import {useState} from 'react'
import useLocalStorage from '../../hooks/useLocalStorage'
import {useNavigate} from 'react-router'
import arrowSVG from './assets/arrow.svg'
import s from './createCompatibility.module.scss'

export default function CreateAffirmations() {
  const navigate = useNavigate()
  const [token] = useLocalStorage('token')
  const apiUrl = process.env.REACT_APP_API_BACKEND_URL
  const [category, setCategory] = useState('На начальном экране')

  const onSubmit = (e) => {
    e.preventDefault()

    const form = e.target
    const formData = new FormData(form)
    const formJson = Object.fromEntries(formData.entries())

    const myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')
    myHeaders.append('Authorization', `Token ${token}`)

    const raw = JSON.stringify({
      textForGirls: formJson.textForGirls,
      textForMan: formJson.textForMan,
      createdData: formJson.createdData,
      category,
    })

    fetch(`/api/affirmations`, {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    }).catch(() => {})

    navigate('/affirmations')
  }

  const deleteAffirmations = () => {
    navigate('/affirmations')
  }

  const goBack = () => {
    navigate('/affirmations')
  }

  return (
    <div className={s.createAffirmations}>
      <div className={s.wrapper}>
        <div className={s.mainIcon}>
          <img
            src={arrowSVG}
            style={{width: '24px', height: '24px', cursor: 'pointer'}}
            onClick={goBack}
            alt="back"
          />
        </div>

        <form method="post" onSubmit={onSubmit}>
          <div className={s.container}>
            <div className={s.textField}>
              <p className={s.textHeader}>Текст для женщин</p>
              <textarea name="textForGirls" className={s.field}></textarea>
            </div>

            <div className={s.textField}>
              <p className={s.textHeader}>Текст для мужчин</p>
              <textarea name="textForMan" className={s.field}></textarea>
            </div>
          </div>
          <div className={s.buttonsContainer}>
            <button type="submit" className={s.buttonSave}>
              Сохранить
            </button>

            <button
              type="button"
              onClick={deleteAffirmations}
              className={s.buttonDelete}
            >
              Удалить
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}
