import {Link, useNavigate} from 'react-router-dom'
import {useContext, useEffect, useState} from 'react'
import {TextField} from '@mui/material'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import cl from 'classnames'

import {CurrentUserContext} from '../../contexts/currentUser'
import useLocalStorage from '../../hooks/useLocalStorage'
import Pagination from '../../components/pagination'

import s from './moonCalendar.module.scss'
export default function MoonCalendarPage() {
  const [moonCalendarList, setMoonCalendarList] = useState<any>()
  //@ts-ignore
  const [currentUserState] = useContext(CurrentUserContext)
  const navigate = useNavigate()
  const [token] = useLocalStorage('token')
  const apiUrl = process.env.REACT_APP_API_BACKEND_URL
  const [page, setpage] = useState(1)
  const [category, setCategory] = useState('day')
  const limit = 5

  const onPaginationChange = (e, pageNumber) => {
    setpage(pageNumber)
    // getCompatibilityList(pageNumber, category)
  }

  const handleClick = (id) => {
    navigate(`/compatibility/${id}/edit`, {replace: false})
  }

  /* const getCompatibilityList = (page, category) => {
    const myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')
    myHeaders.append('Authorization', `Token ${token}`)

    const raw = JSON.stringify({
      limit,
      offset: page - 1,
      category,
    })

    fetch(`/api/compatibility/list`, {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    })
      .then((response) => response.json())
      .then((result) => {
        setMoonCalendarList(result)
      })
      .catch(() => {})
  }

  useEffect(() => {
    getCompatibilityList(1, 'day')
  }, [])
*/
  if (currentUserState.isLoggedIn === false) {
    navigate('/', {replace: false})
  }

  const createCompatibility = () => {
    navigate('/createCompatibility')
  }

  const regExpHelper = (text) => {
    if (text) {
      text = text.replace(
        /<Имя>/g,
        (match) => `<span style="color: rgb(255, 149, 72)">${match}</span>`
      )

      text = text.replace(
        /<Овен2\((.*?)\)>|<Овны2\((.*?)\)>|<Овна2\((.*?)\)>|<Овнов2\((.*?)\) >|<Овну2\((.*?)\)>|<Овнам2\((.*?)\)>|<Овна2\((.*?)\)>|<Овнов2\((.*?)\)>|<Овнами2\((.*?)\)>|<Овен\((.*?)\)>|<Овны\((.*?)\)>|<Овна\((.*?)\)>|<Овнов\((.*?)\) >|<Овну\((.*?)\)>|<Овнам\((.*?)\)>|<Овна\((.*?)\)>|<Овнов\((.*?)\)>|<Овнами\((.*?)\)>|<Овне\((.*?)\)>|<Овнами\((.*?)\)>|<Овном\((.*?)\)>|<Овнах\((.*?)\)|<Овне2\((.*?)\)>|<Овнами2\((.*?)\)>|<Овном2\((.*?)\)>|<Овнах2\((.*?)\)>/g,
        (match, name) =>
          `<span style="color: rgb(76, 180, 255)">${match}</span>`
      )
      return text
    }
    return ''
  }

  return (
    <div className={s.compatibilityPage}>
      <div className={s.raw}>
        <TextField
          id="login"
          sx={{
            width: '310px',
            borderRadius: '12px',
            background: '#1f232e',
            height: '44px',
            display: 'flex',
            justifyContent: 'center',
            input: {
              color: '#556384',
              fontFamily: 'Gilroy',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: 'normal',
              letterSpacing: '0.49px',
              height: '44px',
              padding: '0 16px',
              width: '310px',
              border: 'none',
            },
            fieldset: {
              border: 'none',
            },
          }}
          style={{alignSelf: 'flex-start', margin: '20px 0 20px 0'}}
          placeholder="Поиск"
        />
        <div className={s.buttons}>
          <button className={s.buttonImport}>Импорт из excel</button>
          <button className={s.buttonAdd} onClick={createCompatibility}>
            Добавить
          </button>
        </div>
      </div>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className={cl(s.tableHeader, s.tableHeaderFirst)}>
              ДАТА
            </TableCell>
            <TableCell className={cl(s.tableHeader, s.tableHeaderSecond)}>
              ТЕКСТ
            </TableCell>
            <TableCell className={cl(s.tableHeader, s.tableHeaderThree)}>
              ПОЛОЖЕНИЕ ЛУНЫ
            </TableCell>
            <TableCell className={cl(s.tableHeader, s.tableHeaderFour)}>
              ЗНАК ЗОДИАКА
            </TableCell>
            <TableCell className={cl(s.tableHeader, s.tableHeaderFive)}>
              СТРИЖКА
            </TableCell>
            <TableCell className={cl(s.tableHeader, s.tableHeaderFive)}>
              ПИТАНИЕ
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody className={s.body}>
          {moonCalendarList &&
            moonCalendarList?.rows?.map(
              ({id, text, love, career, friendship}) => (
                <TableRow
                  className={s.cursorActive}
                  key={id}
                  onClick={() => handleClick(id)}
                >
                  <TableCell className={cl(s.tableBody, s.tableBodyId)}>
                    {id}
                  </TableCell>
                  <TableCell
                    className={s.tableBody}
                    dangerouslySetInnerHTML={{
                      __html: regExpHelper(text),
                    }}
                  ></TableCell>
                  <TableCell
                    className={s.tableBody}
                    dangerouslySetInnerHTML={{
                      __html: regExpHelper(love),
                    }}
                  ></TableCell>
                  <TableCell
                    className={s.tableBody}
                    dangerouslySetInnerHTML={{
                      __html: regExpHelper(career),
                    }}
                  ></TableCell>
                  <TableCell
                    className={s.tableBody}
                    dangerouslySetInnerHTML={{
                      __html: regExpHelper(friendship),
                    }}
                  ></TableCell>
                </TableRow>
              )
            )}
        </TableBody>
      </Table>
      <Pagination
        count={Math.ceil(moonCalendarList?.count / limit)}
        onPaginationChange={onPaginationChange}
        page={page}
      />
    </div>
  )
}
