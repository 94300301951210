import {useState} from 'react'
import {MenuItem, Select, ListItemIcon} from '@mui/material'
import useLocalStorage from '../../hooks/useLocalStorage'
import {useNavigate} from 'react-router'
import arrowSVG from './assets/arrow.svg'
import s from './createHoroscope.module.scss'
import CheckIcon from '@mui/icons-material/Check'

export default function CreateHoroscope() {
  const navigate = useNavigate()
  const [token] = useLocalStorage('token')
  const apiUrl = process.env.REACT_APP_API_BACKEND_URL
  const [category, setCategory] = useState('day')

  const onSubmit = (e) => {
    e.preventDefault()

    const form = e.target
    const formData = new FormData(form)
    const formJson = Object.fromEntries(formData.entries())

    const myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')
    myHeaders.append('Authorization', `Token ${token}`)

    const raw = JSON.stringify({
      text: formJson.text,
      love: formJson.love,
      career: formJson.career,
      health: formJson.health,
      category,
    })

    fetch(`/api/horoscope`, {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    }).catch(() => {})

    navigate('/horoscope')
  }

  const deleteHoroscopeList = () => {
    navigate('/horoscope')
  }

  const goBack = () => {
    navigate('/horoscope')
  }

  return (
    <div className={s.createHoroscopePage}>
      <div className={s.wrapper}>
        <div className={s.mainIcon}>
          <img
            src={arrowSVG}
            style={{width: '24px', height: '24px', cursor: 'pointer'}}
            onClick={goBack}
            alt="back"
          />
        </div>
        <div style={{marginTop: '20px'}}>
          <p className={s.label}>КАТЕГОРИЯ</p>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            className={s.select}
            MenuProps={{
              PaperProps: {
                style: {
                  borderRadius: '16px',
                  background: '#2F2F2F',
                  color: '#fff',
                },
              },
            }}
          >
            <MenuItem
              style={{
                borderBottom: '1px solid rgba(255, 255, 255, 0.05)',
                width: '155px!important',
              }}
              value={'day'}
            >
              <ListItemIcon>
                {category === 'day' && <CheckIcon style={{color: '#fff'}} />}
              </ListItemIcon>
              Дни
            </MenuItem>
            <MenuItem
              style={{
                borderBottom: '1px solid rgba(255, 255, 255, 0.05)',
              }}
              value={'week'}
            >
              <ListItemIcon>
                {category === 'week' && <CheckIcon style={{color: '#fff'}} />}
              </ListItemIcon>
              Недели
            </MenuItem>
            <MenuItem
              style={{
                borderBottom: '1px solid rgba(255, 255, 255, 0.05)',
              }}
              value={'month'}
            >
              <ListItemIcon>
                {category === 'month' && <CheckIcon style={{color: '#fff'}} />}
              </ListItemIcon>
              Месяцы
            </MenuItem>
            <MenuItem value={'year'}>
              <ListItemIcon>
                {category === 'year' && <CheckIcon style={{color: '#fff'}} />}
              </ListItemIcon>
              Года
            </MenuItem>
          </Select>
        </div>

        <form method="post" onSubmit={onSubmit}>
          <div className={s.container}>
            <div className={s.textField}>
              <p className={s.textHeader}>Общее</p>
              <textarea name="text" className={s.field}></textarea>
            </div>

            <div className={s.textField}>
              <p className={s.textHeader}>Любовь</p>
              <textarea name="love" className={s.field}></textarea>
            </div>

            <div className={s.textField}>
              <p className={s.textHeader}>Карьера</p>
              <textarea name="career" className={s.field}></textarea>
            </div>

            <div className={s.textField}>
              <p className={s.textHeader}>Здоровье</p>
              <textarea name="health" className={s.field}></textarea>
            </div>
          </div>
          <div className={s.buttonsContainer}>
            <button type="submit" className={s.buttonSave}>
              Сохранить
            </button>

            <button
              type="button"
              onClick={deleteHoroscopeList}
              className={s.buttonDelete}
            >
              Удалить
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}
