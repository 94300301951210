// @ts-nocheck
import {useEffect, useContext} from 'react'

import {CurrentUserContext} from '../../contexts/currentUser'
import useLocalStorage from '../../hooks/useLocalStorage'

const CurrentUserChecker = ({children}) => {
  const [, dispatch] = useContext(CurrentUserContext)
  const [token] = useLocalStorage('token')

  useEffect(() => {
    if (!token) {
      dispatch({type: 'SET_UNAUTHORIZED'})
      return
    }
  }, [dispatch, token])

  return children
}

export default CurrentUserChecker
