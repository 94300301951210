import {PaginationItem, Pagination as PaginationMaterial} from '@mui/material'

import ArrowBackIcon from './assets/arrow-left.svg'
import ArrowForwardIcon from './assets/arrow-right.svg'
import s from './pagination.module.scss'

export default function Pagination({count, onPaginationChange, page = 1}) {
  return (
    <PaginationMaterial
      page={page}
      className={s.pagination}
      count={count}
      onChange={onPaginationChange}
      renderItem={(item) => {
        return (
          <PaginationItem
            selected
            classes={{selected: s.activeItem}}
            slots={{
              previous: () => <img src={ArrowBackIcon} alt="back" />,
              next: () => <img src={ArrowForwardIcon} alt="forward" />,
            }}
            {...item}
          />
        )
      }}
    />
  )
}
