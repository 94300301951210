//@ts-nocheck
import {useNavigate} from 'react-router-dom'
import {useContext, useCallback, useEffect, useState} from 'react'
import {TextField} from '@mui/material'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import cl from 'classnames'

import {CurrentUserContext} from '../../contexts/currentUser'
import useLocalStorage from '../../hooks/useLocalStorage'
import Pagination from '../../components/pagination'

import s from './reviews.module.scss'

export default function ReviewsPage() {
  const [reviewsList, setReviewsList] = useState([])
  const [currentUserState] = useContext(CurrentUserContext)
  const navigate = useNavigate()
  const [token] = useLocalStorage('token')
  const apiUrl = process.env.REACT_APP_API_BACKEND_URL
  const onPaginationChange = (e, pageNumber) => {
    getReviewsList(--pageNumber, 20)
  }

  const getReviewsList = useCallback(
    (offset = 0, limit = 5) => {
      const myHeaders = new Headers()
      myHeaders.append('Authorization', `Token ${token}`)
      myHeaders.append('Content-Type', 'application/json')

      const raw = JSON.stringify({
        offset,
        limit,
      })

      const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow',
      }

      fetch(`/api/review/list`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          setReviewsList(result)
        })
        .catch(() => {})
    },
    [apiUrl, token]
  )
  const formatDate = (dateIn) => {
    // 2023-09-28T16:07:30.260Z --> 00.00.0000 - 00:00
    const parseYearAndTime = dateIn.split('T')
    const date = parseYearAndTime[0]
    // const time = parseYearAndTime[1]
    const dateOut = date.split('-')
    const dateOutOrder = [dateOut[2], dateOut[1], dateOut[0]].join('.')
    //const timeOut = time.substring(0, 5)

    return `${dateOutOrder}`
  }
  useEffect(() => {
    getReviewsList(0, 20)
  }, [getReviewsList])

  if (currentUserState.isLoggedIn === false) {
    navigate('/', {replace: false})
  }

  return (
    <div className={s.reviewPage}>
      <TextField
        id="login"
        sx={{
          width: '310px',
          borderRadius: '12px',
          background: '#1f232e',
          height: '44px',
          display: 'flex',
          justifyContent: 'center',
          input: {
            color: '#556384',
            fontFamily: 'Gilroy',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: 'normal',
            letterSpacing: '0.49px',
            height: '44px',
            padding: '0 16px',
            width: '310px',
            border: 'none',
          },
          fieldset: {
            border: 'none',
          },
        }}
        style={{alignSelf: 'flex-start', margin: '44px 0 20px 0'}}
        placeholder="Поиск"
      />

      <Table>
        <TableHead>
          <TableRow>
            <TableCell className={cl(s.tableHeader, s.tableHeaderFirst)}>
              ТЕКСТ
            </TableCell>
            <TableCell className={cl(s.tableHeader, s.tableHeaderSecond)}>
              РАЗДЕЛ
            </TableCell>
            <TableCell className={cl(s.tableHeader, s.tableHeaderThree)}>
              USER ID
            </TableCell>
            <TableCell className={cl(s.tableHeader, s.tableHeaderFour)}>
              ИМЯ
            </TableCell>
            <TableCell className={s.tableHeader}>ДАТА</TableCell>
          </TableRow>
        </TableHead>
        <TableBody className={s.body}>
          {reviewsList.map(({text, direction, userId, fio, date}) => (
            <TableRow>
              <TableCell className={s.tableBody}>{text}</TableCell>
              <TableCell className={s.tableBody}>{direction}</TableCell>
              <TableCell className={s.tableBody}>{userId}</TableCell>
              <TableCell className={s.tableBody}>{fio}</TableCell>
              <TableCell className={s.tableBody}>{formatDate(date)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Pagination
        count={Math.ceil(reviewsList.length / 20)}
        onPaginationChange={onPaginationChange}
      />
    </div>
  )
}
