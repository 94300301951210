import {useState} from 'react'
import {MenuItem, Select, ListItemIcon} from '@mui/material'
import useLocalStorage from '../../hooks/useLocalStorage'
import {useNavigate} from 'react-router'
import arrowSVG from './assets/arrow.svg'
import s from './createCompatibility.module.scss'
import CheckIcon from '@mui/icons-material/Check'

export default function CreateCompatibility() {
  const navigate = useNavigate()
  const [token] = useLocalStorage('token')
  const apiUrl = process.env.REACT_APP_API_BACKEND_URL
  const [category, setCategory] = useState('day')

  const onSubmit = (e) => {
    e.preventDefault()

    const form = e.target
    const formData = new FormData(form)
    const formJson = Object.fromEntries(formData.entries())

    const myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')
    myHeaders.append('Authorization', `Token ${token}`)

    const raw = JSON.stringify({
      text: formJson.text,
      love: formJson.love,
      career: formJson.career,
      friendship: formJson.friendship,
      category,
    })

    fetch(`/api/compatibility`, {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    }).catch(() => {})

    navigate('/compatibility')
  }

  const deleteCompatibilityList = () => {
    navigate('/compatibility')
  }

  const goBack = () => {
    navigate('/compatibility')
  }

  return (
    <div className={s.createCompatibilityPage}>
      <div className={s.wrapper}>
        <div className={s.mainIcon}>
          <img
            src={arrowSVG}
            style={{width: '24px', height: '24px', cursor: 'pointer'}}
            onClick={goBack}
            alt="back"
          />
        </div>

        <form method="post" onSubmit={onSubmit}>
          <div className={s.container}>
            <div className={s.textField}>
              <p className={s.textHeader}>Общее</p>
              <textarea name="text" className={s.field}></textarea>
            </div>

            <div className={s.textField}>
              <p className={s.textHeader}>Любовь</p>
              <textarea name="love" className={s.field}></textarea>
            </div>

            <div className={s.textField}>
              <p className={s.textHeader}>Карьера</p>
              <textarea name="career" className={s.field}></textarea>
            </div>

            <div className={s.textField}>
              <p className={s.textHeader}>Дружба</p>
              <textarea name="friendship" className={s.field}></textarea>
            </div>
          </div>
          <div className={s.buttonsContainer}>
            <button type="submit" className={s.buttonSave}>
              Сохранить
            </button>

            <button
              type="button"
              onClick={deleteCompatibilityList}
              className={s.buttonDelete}
            >
              Удалить
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}
