import {useNavigate} from 'react-router-dom'
import {useContext, useEffect, useState} from 'react'
import {
  Button,
  IconButton,
  InputLabel,
  Snackbar,
  TextField,
} from '@mui/material'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import CloseIcon from '@mui/icons-material/Close'
import cl from 'classnames'

import {CurrentUserContext} from '../../contexts/currentUser'
import useLocalStorage from '../../hooks/useLocalStorage'
import Pagination from '../../components/pagination'

import s from './compatibility.module.scss'
import {regExpHelper} from './utils'

export default function CompatibilityPage() {
  const [compatibilityList, setCompatibilityList] = useState<any>()
  //@ts-ignore
  const [currentUserState] = useContext(CurrentUserContext)
  const navigate = useNavigate()
  const [token] = useLocalStorage('token')
  const [page, setpage] = useState(1)
  const limit = 4
  const [open, setOpen] = useState(false)
  const [message, setMessage] = useState('')

  const onPaginationChange = (e, pageNumber) => {
    setpage(pageNumber)
    getCompatibilityList(pageNumber)
  }

  const handleClick = (id) => {
    navigate(`/compatibility/${id}/edit`, {replace: false})
  }

  const getCompatibilityList = (page) => {
    const myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')
    myHeaders.append('Authorization', `Token ${token}`)

    const raw = JSON.stringify({
      limit,
      offset: page - 1,
    })

    fetch(`/api/compatibility/list`, {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    })
      .then((response) => response.json())
      .then((result) => {
        setCompatibilityList(result)
      })
      .catch(() => {})
  }

  useEffect(() => {
    getCompatibilityList(1)
  }, [])

  if (currentUserState.isLoggedIn === false) {
    navigate('/', {replace: false})
  }

  const handleFileUpload = (event) => {
    const file = event.target.files[0]

    if (file) {
      const formData = new FormData()
      formData.append('file', file)

      fetch(`api/compatibility/upload`, {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: `Token ${token}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          getCompatibilityList(page)

          if (data.success) {
            setMessage(
              `Файл успешно загружен. Количество дублей: ${data.duplicateCount}`
            )
          } else {
            setMessage(`Файл не загружен. Причина: ${data.error}`)
          }

          setOpen(true)
        })
        .catch(() => {})

      event.target.value = null
    }
  }

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)
  }

  const action = (
    <IconButton
      size="small"
      aria-label="close"
      color="inherit"
      onClick={handleClose}
    >
      <CloseIcon fontSize="small" />
    </IconButton>
  )

  return (
    <div className={s.compatibilityPage}>
      <div className={s.raw}>
        <TextField
          id="login"
          sx={{
            width: '310px',
            borderRadius: '12px',
            background: '#1f232e',
            height: '44px',
            display: 'flex',
            justifyContent: 'center',
            input: {
              color: '#556384',
              fontFamily: 'Gilroy',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: 'normal',
              letterSpacing: '0.49px',
              height: '44px',
              padding: '0 16px',
              width: '310px',
              border: 'none',
            },
            fieldset: {
              border: 'none',
            },
          }}
          style={{alignSelf: 'flex-start', margin: '20px 0 20px 0'}}
          placeholder="Поиск"
        />
        <div className={s.buttons}>
          <div className={s.buttons}>
            <InputLabel htmlFor="upload">
              <Button className={s.buttonImport} component="span">
                Импорт из excel
              </Button>
            </InputLabel>
            <TextField
              type="file"
              sx={{display: 'none'}}
              id="upload"
              onChange={handleFileUpload}
            />
            <button
              className={s.buttonAdd}
              onClick={() => navigate('/createCompatibility')}
            >
              Добавить
            </button>
          </div>
        </div>
      </div>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className={cl(s.tableHeader, s.tableHeaderFirst)}>
              ID СОВМЕСТИМОСТИ
            </TableCell>
            <TableCell className={cl(s.tableHeader, s.tableHeaderSecond)}>
              ОБЩЕЕ
            </TableCell>
            <TableCell className={cl(s.tableHeader, s.tableHeaderThree)}>
              ЛЮБОВЬ
            </TableCell>
            <TableCell className={cl(s.tableHeader, s.tableHeaderFour)}>
              КАРЬЕРА
            </TableCell>
            <TableCell className={cl(s.tableHeader, s.tableHeaderFive)}>
              ДРУЖБА
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody className={s.body}>
          {compatibilityList &&
            compatibilityList?.rows?.map(
              ({id, text, love, career, friendship}) => (
                <TableRow
                  className={s.cursorActive}
                  key={id}
                  onClick={() => handleClick(id)}
                >
                  <TableCell className={cl(s.tableBody, s.tableBodyId)}>
                    {id}
                  </TableCell>
                  <TableCell
                    className={s.tableBody}
                    dangerouslySetInnerHTML={{
                      __html: regExpHelper(text),
                    }}
                  ></TableCell>
                  <TableCell
                    className={s.tableBody}
                    dangerouslySetInnerHTML={{
                      __html: regExpHelper(love),
                    }}
                  ></TableCell>
                  <TableCell
                    className={s.tableBody}
                    dangerouslySetInnerHTML={{
                      __html: regExpHelper(career),
                    }}
                  ></TableCell>
                  <TableCell
                    className={s.tableBody}
                    dangerouslySetInnerHTML={{
                      __html: regExpHelper(friendship),
                    }}
                  ></TableCell>
                </TableRow>
              )
            )}
        </TableBody>
      </Table>
      <Pagination
        count={Math.ceil(compatibilityList?.count / limit)}
        onPaginationChange={onPaginationChange}
        page={page}
      />
      <Snackbar
        open={open}
        autoHideDuration={2000}
        onClose={handleClose}
        message={message}
        action={action}
      />
    </div>
  )
}
