//@ts-nocheck
import {useEffect, useState} from 'react'
import arrowSVG from './assets/arrow.svg'
import {TextField} from '@mui/material'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import {useParams} from 'react-router-dom'
import {useNavigate} from 'react-router-dom'
import moment from 'moment'
import {zodiacSignsMapping} from '../../components/zodiacSigns/zodiacSigns.js'
import CircularProgress from '@material-ui/core/CircularProgress'
import TablePagination from '@mui/material/TablePagination'
import TablePaginationActions from './components/TablePaginationActions'
import TableContainer from '@material-ui/core/TableContainer'
import cl from 'classnames'

import s from './userHoroscope.module.scss'

const UserHoroscope = () => {
  const [horoscopeList, setHoroscopeList] = useState<any>()
  const [compatibilityList, setCompatibilityList] = useState<any>()
  const [isLoading, setIsLoading] = useState(true)
  const [view, setView] = useState('horoscope' || 'compatibility')
  const [category, setCategory] = useState('day')
  const [page, setPage] = useState(0)
  const limit = 6
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const params = useParams()
  const id = params.id
  const navigate = useNavigate()

  const goBack = () => {
    navigate('/users')
  }

  useEffect(() => {
    const getUser = async (page, category) => {
      try {
        const apiUrl = process.env.REACT_APP_API_BACKEND_URL
        const token = localStorage.getItem('token')
        const myHeaders = new Headers()
        myHeaders.append('Content-Type', 'application/json')
        myHeaders.append('Authorization', `Token ${token}`)
        const raw = JSON.stringify({
          id: id,
          offset: page - 1,
          category: category,
        })
        const response = await fetch(`/api/user/one`, {
          method: 'POST',
          headers: myHeaders,
          body: raw,
          redirect: 'follow',
        })
        if (response.ok) {
          const result = await response.json()
          setHoroscopeList(result)
          setCompatibilityList(result)
          setIsLoading(false)
        } else {
          throw new Error('Failed to fetch user data')
        }
      } catch (error) {
        console.error('Error fetching user data:', error)
        setIsLoading(false)
      }
    }

    getUser()
  }, [id])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const formatBirthday = (dateIn) => {
    return moment(dateIn).format('DD.MM.YYYY - HH:mm')
  }

  if (isLoading) {
    return <CircularProgress />
  }

  const regExpHelper = (text) => {
    return text
  }

  const setCategoryAndSave = (newCategory) => {
    if (newCategory !== category) setPage(0)
    setCategory(newCategory)
    setView(newCategory === 'compatibility' ? 'compatibility' : 'horoscope')
  }

  return (
    <div className={s.userHoroscopePage}>
      <div className={s.wrapper}>
        <img
          src={arrowSVG}
          style={{width: '24px', height: '24px', cursor: 'pointer'}}
          onClick={goBack}
          alt="back"
        />
        <p className={s.horoscopeName}>{horoscopeList.fio}</p>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={cl(s.tableHeader, s.headerFirst)}>
                ID
              </TableCell>
              <TableCell className={cl(s.tableHeader, s.headerSecond)}>
                ИМЯ
              </TableCell>
              <TableCell className={cl(s.tableHeader, s.headerThree)}>
                EMAIL
              </TableCell>
              <TableCell className={cl(s.tableHeader, s.headerFour)}>
                ПОЛ
              </TableCell>
              <TableCell className={cl(s.tableHeader, s.headerFive)}>
                С / П
              </TableCell>
              <TableCell className={cl(s.tableHeader, s.headerSix)}>
                ЗНАК ЗОДИАКА
              </TableCell>
              <TableCell className={cl(s.tableHeader, s.headerSeven)}>
                ДАТА И ВРЕМЯ РОЖДЕНИЯ
              </TableCell>
              <TableCell className={cl(s.tableHeader, s.headerEight)}>
                МЕСТО РОЖДЕНИЯ
              </TableCell>
              <TableCell className={cl(s.tableHeader, s.headerNine)}>
                ПОДПИСКА
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell className={s.tableBody}>{id}</TableCell>
              <TableCell className={s.tableBody}>{horoscopeList.fio}</TableCell>
              <TableCell className={s.tableBody}>
                {horoscopeList.email}
              </TableCell>
              <TableCell className={s.tableBody}>{horoscopeList.sex}</TableCell>
              <TableCell className={s.tableBody}>
                {horoscopeList.familyStatus}
              </TableCell>
              <TableCell className={s.tableBody}>
                {zodiacSignsMapping[horoscopeList.zodiacSign]}
              </TableCell>
              <TableCell className={s.tableBody}>
                {formatBirthday(horoscopeList.birthday)}
              </TableCell>
              <TableCell className={s.tableBody}>
                {horoscopeList.placeOfBirth}
              </TableCell>
              <TableCell className={s.tableBody}>
                {horoscopeList.subscription}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>

        <div className={s.secondHeader}>
          <div
            style={{color: category === 'day' ? '#fff' : ''}}
            className={s.secondTableHeader}
          >
            <div
              onClick={(e) => {
                setCategoryAndSave('day')
              }}
            >
              День
            </div>
          </div>
          <div
            style={{color: category === 'week' ? '#fff' : ''}}
            className={s.secondTableHeader}
          >
            <div
              onClick={(e) => {
                setCategoryAndSave('week')
              }}
            >
              Неделя
            </div>
          </div>
          <div
            style={{color: category === 'month' ? '#fff' : ''}}
            className={s.secondTableHeader}
          >
            <div
              onClick={(e) => {
                setCategoryAndSave('month')
              }}
            >
              Месяц
            </div>
          </div>
          <div
            style={{color: category === 'year' ? '#fff' : ''}}
            className={cl(s.secondTableHeader, s.secondTableHeaderImg)}
          >
            <div
              onClick={(e) => {
                setCategoryAndSave('year')
              }}
            >
              Год
            </div>
          </div>
          <div
            style={{color: category === 'compatibility' ? '#fff' : ''}}
            className={s.secondTableHeader}
          >
            <div
              onClick={(e) => {
                setCategory('compatibility')
                setView('compatibility')
              }}
            >
              Совместимости
            </div>
          </div>
        </div>
        <TextField
          id="login"
          sx={{
            width: '310px',
            borderRadius: '12px',
            background: '#1f232e',
            height: '44px',
            display: 'flex',
            justifyContent: 'center',

            input: {
              color: '#556384',
              fontFamily: 'Gilroy',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: 'normal',
              letterSpacing: '0.49px',
              height: '44px',
              padding: '0 16px',
              width: '310px',
              border: 'none',
            },
            fieldset: {
              border: 'none',
            },
          }}
          style={{alignSelf: 'flex-start', margin: '20px 0 20px 0'}}
          placeholder="Поиск"
        />

        {horoscopeList && view === 'horoscope' && (
          <TableContainer className={s.tableContainer}>
            <Table>
              <TableHead>
                <TableRow className={s.classTableRow}>
                  <TableCell className={s.classTableRow}>
                    ID ГОРОСКОПА
                  </TableCell>
                  <TableCell className={s.classTableRow}>ОБЩЕЕ</TableCell>
                  <TableCell className={s.classTableRow}>ЛЮБОВЬ</TableCell>
                  <TableCell className={s.classTableRow}>КАРЬЕРА</TableCell>
                  <TableCell className={s.classTableRow}>ЗДОРОВЬЕ</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(horoscopeList?.horoscopeSequence || [])
                  .filter((el) => el.category === category)
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map(({_id, text, love, career, health}, i) => (
                    <TableRow key={_id + i}>
                      <TableCell
                        className={(s.cursorActiveText, s.tableHeaderID)}
                      >
                        {_id}
                      </TableCell>
                      <TableCell
                        className={(s.cursorActiveText, s.tableHeaderFirst)}
                        dangerouslySetInnerHTML={{
                          __html: regExpHelper(text),
                        }}
                      />
                      <TableCell
                        className={(s.cursorActiveText, s.tableHeaderSecond)}
                        dangerouslySetInnerHTML={{
                          __html: regExpHelper(love),
                        }}
                      />
                      <TableCell
                        className={(s.cursorActiveText, s.tableHeaderThree)}
                        dangerouslySetInnerHTML={{
                          __html: regExpHelper(career),
                        }}
                      />
                      <TableCell
                        className={(s.cursorActiveText, s.tableHeaderFour)}
                        dangerouslySetInnerHTML={{
                          __html: regExpHelper(health),
                        }}
                      />
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            <div className={s.paginationHolder}>
              <TablePagination
                component="div"
                className={s.pagination}
                rowsPerPageOptions={[]}
                rowsPerPage={5}
                count={
                  horoscopeList?.horoscopeSequence?.filter(
                    (el) => el.category === category
                  ).length
                }
                page={page}
                onPageChange={handleChangePage}
                ActionsComponent={TablePaginationActions}
              />
            </div>
          </TableContainer>
        )}
        {compatibilityList && view === 'compatibility' && (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow className={s.classTableRow}>
                  <TableCell className={s.classTableRow}>
                    ID СОВМЕСТИМОСТИ
                  </TableCell>
                  <TableCell className={s.classTableRow}>ОБЩЕЕ</TableCell>
                  <TableCell className={s.classTableRow}>ЛЮБОВЬ</TableCell>
                  <TableCell className={s.classTableRow}>КАРЬЕРА</TableCell>
                  <TableCell className={s.classTableRow}>ДРУЖБА</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(compatibilityList?.compatibilitySequence || [])
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map(({_id, text, love, career, friendship}, i) => (
                    <TableRow key={_id + i}>
                      <TableCell
                        className={(s.cursorActiveText, s.tableHeaderID)}
                      >
                        {_id}
                      </TableCell>
                      <TableCell
                        className={(s.cursorActiveText, s.tableHeaderFirst)}
                        dangerouslySetInnerHTML={{
                          __html: regExpHelper(text),
                        }}
                      ></TableCell>
                      <TableCell
                        className={(s.cursorActiveText, s.tableHeaderSecond)}
                        dangerouslySetInnerHTML={{
                          __html: regExpHelper(love),
                        }}
                      ></TableCell>
                      <TableCell
                        className={(s.cursorActiveText, s.tableHeaderThree)}
                        dangerouslySetInnerHTML={{
                          __html: regExpHelper(career),
                        }}
                      ></TableCell>
                      <TableCell
                        className={(s.cursorActiveText, s.tableHeaderFour)}
                        dangerouslySetInnerHTML={{
                          __html: regExpHelper(friendship),
                        }}
                      ></TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            <div className={s.paginationHolder}>
              <TablePagination
                component="div"
                className={s.pagination}
                rowsPerPageOptions={[]}
                rowsPerPage={rowsPerPage}
                count={compatibilityList.compatibilitySequence?.length}
                page={page}
                onPageChange={handleChangePage}
                ActionsComponent={TablePaginationActions}
              />
            </div>
          </TableContainer>
        )}
      </div>
    </div>
  )
}

export default UserHoroscope
