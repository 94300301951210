// @ts-nocheck
import {useContext} from 'react'
import exitSVG from './assets/exit.svg'
import {NavLink} from 'react-router-dom'
import {CurrentUserContext} from '../../contexts/currentUser'

import s from './topBar.module.scss'

const TopBar = () => {
  const [, dispatch] = useContext(CurrentUserContext)

  const handleLogout = () => {
    dispatch({type: 'LOGOUT'})
    localStorage.clear()
  }

  return (
    <div className={s.horizontalMenu}>
      <div className={s.link}>
        <NavLink to="/horoscope">Гороскопы</NavLink>
      </div>
      <div className={s.link}>
        <NavLink to="/compatibility">Совместимости</NavLink>
      </div>
      { <div className={s.link}>
        <NavLink to="/affirmations">Аффирмации</NavLink>
      </div> }
      {/* <div className={s.link}>
        <NavLink to="/mooncalendar">Лунный календарь</NavLink>
      </div> */}
      <div className={s.link}>
        <NavLink to="/users">Пользователи</NavLink>
      </div>
      <div className={s.link}>
        <NavLink to="/reviews">Отзывы</NavLink>
      </div>
      <img
        src={exitSVG}
        style={{width: '44px', cursor: 'pointer'}}
        onClick={handleLogout}
        alt="exit"
      />
    </div>
  )
}

export default TopBar
