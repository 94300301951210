import TopBar from '../topbar/topbar'
import Footer from '../footer/footer'

import s from './layout.module.scss'

const Layout = ({children}) => {
  return (
    <div className={s.pageWrapper}>
      <div className={s.contentWrapper}>
        <TopBar />
        {children}
        <Footer />
      </div>
    </div>
  )
}
export default Layout
