import s from './footer.module.scss'

const Footer = () => {
  return (
    <div className={s.pageWrapper}>
      <div className={s.contentWrapper}></div>
    </div>
  )
}

export default Footer
