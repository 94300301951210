import {Routes as Switch, Route} from 'react-router-dom'
import UserPage from './pages/user'
import LoginPage from './pages/login'
import HoroscopePage from './pages/horoscope'
import CompatibilityPage from './pages/compatibility'
import AffirmationsPage from './pages/affirmations'
import MoonCalendarPage from './pages/moonCalendar'
import ReviewsPage from './pages/reviews'
import Layout from './components/layout'
import CreateHoroscope from './pages/createHoroscope'
import EditHoroscope from './pages/editHoroscope'
import CreateCompatibility from './pages/createCompatibility'
import EditCompatibility from './pages/editCompatibility'
import CreateAffirmations from './pages/createaffirmations'
import UserHoroscope from './pages/userHoroscope'

const Routes = () => {
  return (
    <Switch>
      <Route path="/" element={<LoginPage />} />
      <Route
        path="/horoscope"
        element={
          <Layout>
            <HoroscopePage />
          </Layout>
        }
      />
      <Route
        path="/compatibility"
        element={
          <Layout>
            <CompatibilityPage />
          </Layout>
        }
      />
      <Route
        path="/affirmations"
        element={
          <Layout>
            <AffirmationsPage />
          </Layout>
        }
      />
      <Route
        path="/mooncalendar"
        element={
          <Layout>
            <MoonCalendarPage />
          </Layout>
        }
      />
      <Route
        path="/users"
        element={
          <Layout>
            <UserPage />
          </Layout>
        }
      />
      <Route
        path="/reviews"
        element={
          <Layout>
            <ReviewsPage />
          </Layout>
        }
      />
      <Route path="/createHoroscope" element={<CreateHoroscope />} />
      <Route path="/horoscope/:id/edit" element={<EditHoroscope />} />
      <Route path="/createCompatibility" element={<CreateCompatibility />} />
      <Route path="/compatibility/:id/edit" element={<EditCompatibility />} />
      <Route path="/createAffirmations" element={<CreateAffirmations />} />
      <Route path="/userHoroscope/:id" element={<UserHoroscope />} />
    </Switch>
  )
}
export default Routes
