export const regExpHelper = (text) => {
  if (text) {
    text = text.replace(
      /<Имя>/g,
      (match) => `<span style="color: rgb(255, 149, 72)">${match}</span>`
    )

    text = text.replace(
      /<Овен\((.*?)\)>|<Овны\((.*?)\)>|<Овна\((.*?)\)>|<Овнов\((.*?)\) >|<Овну\((.*?)\)>|<Овнам\((.*?)\)>|<Овна\((.*?)\)>|<Овнов\((.*?)\)>|<Овнами\((.*?)\)>|<Овне\((.*?)\)>|<Овнами\((.*?)\)>|<Овном\((.*?)\)>|<Овнах\((.*?)\)>/g,
      (match, name) =>
        `<span style="color: rgb(76, 180, 255)">${match}</span>`
    )
    return text
  }
  return ''
}