//@ts-nocheck
import {useEffect, useState, useContext, useCallback} from 'react'
import {useNavigate} from 'react-router-dom'
import {TextField} from '@mui/material'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import {Link} from 'react-router-dom'
import {CurrentUserContext} from '../../contexts/currentUser'
import useLocalStorage from '../../hooks/useLocalStorage'
import Pagination from '../../components/pagination'
import moment from 'moment'
import {zodiacSignsMapping} from '../../components/zodiacSigns/zodiacSigns.js'

import s from './user.module.scss'

export default function UserPage() {
  const [userList, setUserList] = useState([])
  const [currentUserState] = useContext(CurrentUserContext)
  const navigate = useNavigate()
  const [token] = useLocalStorage('token')
  const apiUrl = process.env.REACT_APP_API_BACKEND_URL

  const onPaginationChange = (e, pageNumber) => {
    getUserList(--pageNumber, 20)
  }

  const handleClick = (id) => {
    navigate(`/userHoroscope/${id}`, {replace: false})
  }

  const getUserList = useCallback(
    (offset = 0, limit = 2) => {
      const myHeaders = new Headers()
      myHeaders.append('Content-Type', 'application/json')
      myHeaders.append('Authorization', `Token ${token}`)

      const raw = JSON.stringify({
        offset,
        limit,
      })

      const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow',
      }

      fetch(`/api/user/list`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          setUserList(result)
        })
        .catch(() => {})
    },
    [apiUrl, token]
  )

  const formatBirthday = (dateIn) => {
    return moment(dateIn).format('DD.MM.YYYY - HH:mm')
  }

  useEffect(() => {
    getUserList(0, 20)
  }, [getUserList])

  if (currentUserState.isLoggedIn === false) {
    navigate('/', {replace: false})
  }

  return (
    <div className={s.userPage}>
      <TextField
        id="login"
        sx={{
          width: '310px',
          borderRadius: '12px',
          background: '#1f232e',
          height: '44px',
          display: 'flex',
          justifyContent: 'center',
          input: {
            color: '#556384',
            fontFamily: 'Gilroy',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: 'normal',
            letterSpacing: '0.49px',
            height: '44px',
            padding: '0 16px',
            width: '310px',
            border: 'none',
          },
          fieldset: {
            border: 'none',
          },
        }}
        style={{alignSelf: 'flex-start', margin: '44px 0 20px 0'}}
        placeholder="Поиск"
      />

      <Table>
        <TableHead>
          <TableRow>
            <TableCell className={s.tableHeader}>ID</TableCell>
            <TableCell className={s.tableHeader}>ИМЯ</TableCell>
            <TableCell className={s.tableHeader}>EMAIL</TableCell>
            <TableCell className={s.tableHeader}>ПОЛ</TableCell>
            <TableCell className={s.tableHeader}>С / П</TableCell>
            <TableCell className={s.tableHeader}>ЗНАК ЗОДИАКА</TableCell>
            <TableCell className={s.tableHeader}>
              ДАТА И ВРЕМЯ РОЖДЕНИЯ
            </TableCell>
            <TableCell className={s.tableHeader}>МЕСТО РОЖДЕНИЯ</TableCell>
            <TableCell className={s.tableHeader}>ПОДПИСКА</TableCell>
          </TableRow>
        </TableHead>
        <TableBody className={s.body}>
          {userList.map(
            ({
              id,
              fio,
              email,
              sex,
              familyStatus,
              zodiacSign,
              birthday,
              placeOfBirth,
              subscription,
            }) => (
              <TableRow
                onClick={() => handleClick(id)}
                style={{cursor: 'pointer'}}
              >
                <TableCell className={s.tableBody}>{id}</TableCell>
                <TableCell className={s.tableBody}>{fio}</TableCell>
                <TableCell className={s.tableBody}>{email}</TableCell>
                <TableCell className={s.tableBody}>{sex}</TableCell>
                <TableCell className={s.tableBody}>{familyStatus}</TableCell>
                <TableCell className={s.tableBody}>
                  {zodiacSignsMapping[zodiacSign]}
                </TableCell>
                <TableCell className={s.tableBody}>
                  {formatBirthday(birthday)}
                </TableCell>
                <TableCell className={s.tableBody}>{placeOfBirth}</TableCell>
                <TableCell className={s.tableBody}>{subscription}</TableCell>
              </TableRow>
            )
          )}
        </TableBody>
      </Table>

      <Pagination
        count={Math.ceil(userList.length / 20)}
        onPaginationChange={onPaginationChange}
      />
    </div>
  )
}
