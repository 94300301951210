import arrowSVG from './assets/arrow.svg'
import useLocalStorage from '../../hooks/useLocalStorage'
import {useParams} from 'react-router-dom'
import {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import s from './editHoroscope.module.scss'
import {MenuItem, Select, ListItemIcon} from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'

export default function EditHoroscope() {
  const navigate = useNavigate()
  const [horoscopeResult, setHoroscopeResult] = useState<any>()
  const [text, setText] = useState('')
  const [love, setLove] = useState('')
  const [career, setCareer] = useState('')
  const [health, setHealth] = useState('')
  const params = useParams()
  const [category, setCategory] = useState('day')
  const id = params.id
  const [token] = useLocalStorage('token')
  const apiUrl = process.env.REACT_APP_API_BACKEND_URL

  const goBack = () => {
    navigate('/horoscope')
  }

  const onChange = (e) => {
    switch (e.target.name) {
      case 'text':
        setText(e.target.value)
        break
      case 'love':
        setLove(e.target.value)
        break
      case 'career':
        setCareer(e.target.value)
        break
      case 'health':
        setHealth(e.target.value)
        break
      case 'category':
        setCategory(e.target.value)
        break
      default:
        break
    }
  }

  const onSubmit = (e) => {
    e.preventDefault()

    const myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')
    myHeaders.append('Authorization', `Token ${token}`)

    const raw = JSON.stringify({
      id,
      text,
      love,
      career,
      health,
      category,
    })

    fetch(`/api/horoscope`, {
      method: 'PUT',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    }).catch(() => {})

    navigate('/horoscope')
  }

  const getHoroscope = () => {
    const myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')
    myHeaders.append('Authorization', `Token ${token}`)

    const raw = JSON.stringify({
      id: id,
    })
    fetch(`/api/horoscope/one`, {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    })
      .then((response) => response.json())
      .then((result) => {
        setCategory(result.category)
        setText(result.text)
        setLove(result.love)
        setCareer(result.career)
        setHealth(result.health)
        setCategory(result.category)
        setHoroscopeResult(result)
      })
      .catch(() => {})
  }

  const deleteHoroscope = () => {
    const myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')
    myHeaders.append('Authorization', `Token ${token}`)

    const raw = JSON.stringify({
      id,
    })

    fetch(`/api/horoscope/delete`, {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    })

    navigate('/horoscope')
  }

  useEffect(() => {
    getHoroscope()
  }, [])

  return (
    <div className={s.editHoroscopePage}>
      <div className={s.wrapper}>
        <div className={s.mainIcon}>
          <img
            src={arrowSVG}
            style={{width: '24px', height: '24px', cursor: 'pointer'}}
            onClick={goBack}
            alt="back"
          />
        </div>
        <p className={s.horoscopeId}>{id}</p>
        <div className={s.timeContainer}>
          <div className={s.createTime}>
            <p className={s.label}>КАТЕГОРИЯ</p>

            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              className={s.select}
              MenuProps={{
                PaperProps: {
                  style: {
                    borderRadius: '16px',
                    background: '#2F2F2F',
                    color: '#fff',
                  },
                },
              }}
            >
              <MenuItem
                style={{
                  borderBottom: '1px solid rgba(255, 255, 255, 0.05)',
                }}
                value={'day'}
              >
                <ListItemIcon>
                  {category === 'day' && <CheckIcon style={{color: '#fff'}} />}
                </ListItemIcon>
                Дни
              </MenuItem>
              <MenuItem
                style={{
                  borderBottom: '1px solid rgba(255, 255, 255, 0.05)',
                }}
                value={'week'}
              >
                <ListItemIcon>
                  {category === 'week' && <CheckIcon style={{color: '#fff'}} />}
                </ListItemIcon>
                Недели
              </MenuItem>
              <MenuItem
                style={{
                  borderBottom: '1px solid rgba(255, 255, 255, 0.05)',
                }}
                value={'month'}
              >
                <ListItemIcon>
                  {category === 'month' && (
                    <CheckIcon style={{color: '#fff'}} />
                  )}
                </ListItemIcon>
                Месяцы
              </MenuItem>
              <MenuItem value={'year'}>
                <ListItemIcon>
                  {category === 'year' && <CheckIcon style={{color: '#fff'}} />}
                </ListItemIcon>
                Года
              </MenuItem>
            </Select>
          </div>
          <div className={s.createTime}>
            <p>ВРЕМЯ СОЗДАНИЯ</p>
            <p className={s.timeTextcreate}>12.12.12 в 16:32</p>
          </div>
          <div className={s.createTime}>
            <p>ВРЕМЯ ПОСЛЕДНЕГО ИЗМЕНЕНИЯ</p>
            <p className={s.timeTextcreate}>12.12.12 в 16:32</p>
          </div>
        </div>
        {horoscopeResult && (
          <form method="put" onSubmit={onSubmit}>
            <div className={s.containerInputs}>
              <div className={s.textField}>
                <p className={s.textHeader}>Общее</p>

                <textarea
                  name="text"
                  value={text}
                  onChange={onChange}
                  className={s.field}
                ></textarea>
              </div>

              <div className={s.textField}>
                <p className={s.textHeader}>Любовь</p>

                <textarea
                  name="love"
                  value={love}
                  onChange={onChange}
                  className={s.field}
                ></textarea>
              </div>

              <div className={s.textField}>
                <p className={s.textHeader}>Карьера</p>

                <textarea
                  name="career"
                  value={career}
                  onChange={onChange}
                  className={s.field}
                ></textarea>
              </div>

              <div className={s.textField}>
                <p className={s.textHeader}>Здоровье</p>

                <textarea
                  name="health"
                  value={health}
                  onChange={onChange}
                  className={s.field}
                ></textarea>
              </div>
            </div>
            <div className={s.buttonsContainer}>
              <button type="submit" className={s.buttonSave}>
                Сохранить
              </button>

              <button
                type="button"
                onClick={deleteHoroscope}
                className={s.buttonDelete}
              >
                Удалить
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  )
}
