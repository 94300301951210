import {useNavigate} from 'react-router-dom'
import {useContext, useEffect, useState} from 'react'
import {TextField, Button, InputLabel} from '@mui/material'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import cl from 'classnames'
import Snackbar from '@mui/material/Snackbar'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import {useParams} from 'react-router-dom'
import {CurrentUserContext} from '../../contexts/currentUser'
import useLocalStorage from '../../hooks/useLocalStorage'
import Pagination from '../../components/pagination'

import s from './affirmations.module.scss'

export default function AffirmationsPage() {
  const [affirmationsList, setAffirmationsList] = useState<any>()
  console.log({affirmationsList})
  //@ts-ignore
  const [currentUserState] = useContext(CurrentUserContext)
  const navigate = useNavigate()
  const [token] = useLocalStorage('token')
  const apiUrl = process.env.REACT_APP_API_BACKEND_URL
  const [page, setpage] = useState(1)
  const [category, setCategory] = useState('На начальном экране')
  const limit = 30
  const params = useParams()
  const id = params.id
  const [open, setOpen] = useState(false)
  const [message, setMessage] = useState('')
  const [editingItem, setEditingItem] = useState({
    id: null,
    field: null,
    value: '',
    textForGirls: '',
    textForMan: '',
  })

  const handleEdit = (
    id,
    field,
    value,
    initialTextForGirls,
    initialTextForMan
  ) => {
    setEditingItem({
      id,
      field,
      value,
      textForGirls: initialTextForGirls,
      textForMan: initialTextForMan,
    })
  }

  const handleBlur = (e) => {
    e.preventDefault()
    if (editingItem.value.length > 55) {
      setMessage('Текст не должен превышать 55 символов')
      setOpen(true)
      return
    }
    const myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')
    myHeaders.append('Authorization', `Token ${token}`)

    const raw = JSON.stringify({
      id: editingItem.id,
      textForGirls:
        editingItem.field === 'textForGirls'
          ? editingItem.value
          : editingItem.textForGirls,
      textForMan:
        editingItem.field === 'textForMan'
          ? editingItem.value
          : editingItem.textForMan,
      category,
    })

    fetch(`/api/affirmations`, {
      method: 'PUT',
      headers: myHeaders,
      body: raw,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok')
        }
        return response.json()
      })
      .then(() => {
        setMessage('Успешно обновлено')
        setOpen(true)

        getAffirmationsList(page, category)
      })
      .catch((error) => {
        setMessage('Ошибка при обновлении')
        setOpen(true)
      })
  }

  const onPaginationChange = (e, pageNumber) => {
    setpage(pageNumber)
    getAffirmationsList(pageNumber, category)
  }

  const getAffirmationsList = (page, category) => {
    const myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')
    myHeaders.append('Authorization', `Token ${token}`)

    const raw = JSON.stringify({
      limit,
      offset: page - 1,
      category,
    })

    fetch(`/api/affirmations/list`, {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    })
      .then((response) => response.json())
      .then((result) => {
        if (category === 'На начальном экране') {
          const filteredResult = {
            ...result,
            // rows: result.rows.filter(
            //   ({textForGirls, textForMan}) =>
            //     textForGirls.length <= 55 && textForMan.length <= 55
            // ),
          }
          setAffirmationsList(filteredResult)
        } else {
          setAffirmationsList(result)
        }
      })
      .catch(() => {})
  }

  const setCategoryAndSave = (newCategory) => {
    setCategory(newCategory)
    localStorage.setItem('currentCategory', newCategory)
    getAffirmationsList(1, newCategory)
  }

  useEffect(() => {
    const savedCategory = localStorage.getItem('currentCategory')
    if (savedCategory) {
      setCategory(savedCategory)
    }
    getAffirmationsList(1, savedCategory || 'На начальном экране')
  }, [])

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.key === 'Escape' || event.key === 'Enter') {
        console.log('Close')
        setEditingItem({
          id: null,
          field: null,
          value: '',
          textForGirls: '',
          textForMan: '',
        })
      }
    }
    window.addEventListener('keydown', handleEsc)

    return () => {
      window.removeEventListener('keydown', handleEsc)
    }
  }, [])

  if (currentUserState.isLoggedIn === false) {
    navigate('/', {replace: false})
  }

  const handleFileUpload = (event) => {
    const file = event.target.files[0]

    if (file) {
      const formData = new FormData()
      formData.append('file', file)

      fetch(`api/affirmations/upload`, {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: `Token ${token}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          getAffirmationsList(page, category)

          if (data.success) {
            setMessage(
              `Файл успешно загружен. Количество дублей: ${data.duplicateCount}`
            )
          } else {
            setMessage(`Файл не загружен. Причина: ${data.error}`)
          }

          setOpen(true)
        })
        .catch((error) => {})

      event.target.value = null
    }
  }

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)
  }

  const action = (
    <IconButton
      size="small"
      aria-label="close"
      color="inherit"
      onClick={handleClose}
    >
      <CloseIcon fontSize="small" />
    </IconButton>
  )
  const handleInputChange = (e) => {
    if (e.target.value.length <= 55) {
      setEditingItem({
        ...editingItem,
        value: e.target.value,
      })
    }
  }
  const formatDate = (dateIn) => {
    const parseYearAndTime = dateIn.split('T')
    const date = parseYearAndTime[0]
    const dateOut = date.split('-')
    const dateOutOrder = [dateOut[2], dateOut[1], dateOut[0]].join('.')

    return `${dateOutOrder}`
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleBlur(e)
    }

    if (e.key === 'Enter' || e.key === 'Escape') {
      setEditingItem({
        id: null,
        field: null,
        value: '',
        textForGirls: '',
        textForMan: '',
      })
    }
  }

  return (
    <div className={s.affirmationsPage}>
      <div className={s.secondHeader}>
        <div className={s.raw}>
          <div
            style={{color: category === 'На начальном экране' ? '#fff' : ''}}
            className={s.secondTableHeader}
          >
            <div
              onClick={() => {
                setCategoryAndSave('На начальном экране')
              }}
            >
              На начальном экране
            </div>
          </div>
          <div
            style={{color: category === 'Общие темы' ? '#fff' : ''}}
            className={s.secondTableHeader}
          >
            <div
              onClick={() => {
                setCategoryAndSave('Общие темы')
              }}
            >
              Общие темы
            </div>
          </div>
          <div
            style={{color: category === 'После расставания' ? '#fff' : ''}}
            className={s.secondTableHeader}
          >
            <div
              onClick={() => {
                setCategoryAndSave('После расставания')
              }}
            >
              После расставания
            </div>
          </div>
          <div
            style={{color: category === 'Уверенность в себе' ? '#fff' : ''}}
            className={s.secondTableHeader}
          >
            <div
              onClick={() => {
                setCategoryAndSave('Уверенность в себе')
              }}
            >
              Уверенность в себе
            </div>
          </div>
          <div
            style={{color: category === 'Личностный рост' ? '#fff' : ''}}
            className={s.secondTableHeader}
          >
            <div
              onClick={() => {
                setCategoryAndSave('Личностный рост')
              }}
            >
              Личностный рост
            </div>
          </div>
          <div
            style={{color: category === 'Найдите спокойствие' ? '#fff' : ''}}
            className={s.secondTableHeader}
          >
            <div
              onClick={() => {
                setCategoryAndSave('Найдите спокойствие')
              }}
            >
              Найдите спокойствие
            </div>
          </div>
          <div
            style={{color: category === 'Привлечение любви' ? '#fff' : ''}}
            className={s.secondTableHeader}
          >
            <div
              onClick={() => {
                setCategoryAndSave('Привлечение любви')
              }}
            >
              Привлечение любви
            </div>
          </div>
          <div
            style={{
              color: category === 'Укрепите отношения' ? '#fff' : '',
            }}
            className={s.secondTableHeader}
          >
            <div
              onClick={() => {
                setCategoryAndSave('Укрепите отношения')
              }}
            >
              Укрепите отношения
            </div>
          </div>
          <div
            style={{color: category === 'Боритесь с депрессией' ? '#fff' : ''}}
            className={s.secondTableHeader}
          >
            <div
              onClick={() => {
                setCategoryAndSave('Боритесь с депрессией')
              }}
            >
              Боритесь с депрессией
            </div>
          </div>
        </div>
        <div className={s.container}>
          <div className={s.field}>
            <TextField
              id="login"
              sx={{
                width: '310px',
                borderRadius: '12px',
                background: '#1f232e',
                height: '44px',
                display: 'flex',
                justifyContent: 'center',
                input: {
                  color: '#556384',
                  fontFamily: 'Gilroy',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: 500,
                  lineHeight: 'normal',
                  letterSpacing: '0.49px',
                  height: '44px',
                  padding: '0 16px',
                  width: '310px',
                  border: 'none',
                },
                fieldset: {
                  border: 'none',
                },
              }}
              style={{alignSelf: 'flex-start'}}
              placeholder="Поиск"
            />
            <div className={s.buttons}>
              <InputLabel htmlFor="upload">
                <Button className={s.buttonImport} component="span">
                  Импорт из excel
                </Button>
              </InputLabel>
              <TextField
                type="file"
                sx={{display: 'none'}}
                id="upload"
                onChange={handleFileUpload}
              />
              <button
                className={s.buttonAdd}
                onClick={() => navigate('/createAffirmations')}
              >
                Добавить
              </button>
            </div>
          </div>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={cl(s.tableHeader, s.tableHeaderFirst)}>
                  ID
                </TableCell>
                <TableCell className={cl(s.tableHeader, s.tableHeaderSecond)}>
                  ТЕКСТ ДЛЯ ЖЕНЩИН
                </TableCell>
                <TableCell className={cl(s.tableHeader, s.tableHeaderThree)}>
                  ТЕКСТ ДЛЯ МУЖЧИН
                </TableCell>
                <TableCell className={cl(s.tableHeader, s.tableHeaderFour)}>
                  ДАТА СОЗДАНИЯ
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody className={s.body}>
              {affirmationsList &&
                affirmationsList?.rows?.map(
                  ({
                    id,
                    textForGirls: initialTextForGirls,
                    textForMan: initialTextForMan,
                    createdAt,
                  }) => (
                    <TableRow className={s.cursorActive} key={id}>
                      <TableCell className={s.tableBody}>{id}</TableCell>
                      <TableCell
                        className={s.tableBody}
                        onClick={() =>
                          handleEdit(
                            id,
                            'textForGirls',
                            initialTextForGirls,
                            initialTextForGirls,
                            initialTextForMan
                          )
                        }
                      >
                        {editingItem.id === id &&
                        editingItem.field === 'textForGirls' ? (
                          <TextField
                            value={editingItem.value}
                            onChange={handleInputChange}
                            fullWidth
                            multiline
                            margin="none"
                            onBlur={handleBlur}
                            onKeyDown={handleKeyDown}
                            inputProps={{maxLength: 55}}
                            focused={true}
                          />
                        ) : (
                          initialTextForGirls
                        )}
                      </TableCell>

                      <TableCell
                        className={s.tableBody}
                        onClick={() =>
                          handleEdit(
                            id,
                            'textForMan',
                            initialTextForMan,
                            initialTextForGirls,
                            initialTextForMan
                          )
                        }
                      >
                        {editingItem.id === id &&
                        editingItem.field === 'textForMan' ? (
                          <TextField
                            value={editingItem.value}
                            onChange={handleInputChange}
                            fullWidth
                            margin="none"
                            onBlur={handleBlur}
                            onKeyDown={handleKeyDown}
                            inputProps={{maxLength: 55}}
                          />
                        ) : (
                          initialTextForMan
                        )}
                      </TableCell>
                      <TableCell className={s.tableBody}>
                        {formatDate(createdAt)}
                      </TableCell>
                    </TableRow>
                  )
                )}
            </TableBody>
          </Table>
          {affirmationsList?.rows && affirmationsList.rows.length > 0 && (
            <Pagination
              count={Math.ceil(affirmationsList.count / limit)}
              onPaginationChange={onPaginationChange}
              page={page}
            />
          )}
          <Snackbar
            open={open}
            autoHideDuration={2000}
            onClose={handleClose}
            message={message}
            action={action}
          />
        </div>
      </div>
    </div>
  )
}
