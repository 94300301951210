import {Button, TextField} from '@mui/material'
import s from './login.module.scss'
import {useState, useContext} from 'react'
import {useNavigate} from 'react-router-dom'
import {CurrentUserContext} from '../../contexts/currentUser'

export default function LoginPage() {
  const [email, setEmail] = useState<string>()
  const [password, setPassword] = useState<string>()
  const [errors, setErrors] = useState([])
  const navigate = useNavigate()
  //@ts-ignore
  const [, dispatch] = useContext(CurrentUserContext)
  const apiUrl = process.env.REACT_APP_API_BACKEND_URL

  const handleSubmit = (e) => {
    e.preventDefault()
    let myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')

    let raw = JSON.stringify({
      email,
      password,
    })

    fetch(`/api/user/login`, {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    })
      .then((response) => response.json())
      .then((result) => {
        if (!Object.keys(result).includes('errors')) {
          dispatch({type: 'SET_AUTHORIZED', payload: result})
          localStorage.setItem('token', result.token)
          navigate('users', {replace: false})
        }
        setErrors(result.errors)
      })
      .catch(() => {})
  }

  return (
    <div className={s.wrapper}>
      <form onSubmit={(e) => handleSubmit(e)}>
        {errors.length > 0 && (
          <div style={{width: '310px'}}>
            {errors.map((error) => (
              <div>{error}</div>
            ))}
          </div>
        )}
        <TextField
          id="email"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setErrors([])
            setEmail(event.target.value)
          }}
          sx={{
            width: '310px',
            borderRadius: '12px',
            background: '#1f232e',
            height: '44px',
            display: 'flex',
            justifyContent: 'center',
            input: {
              fontFamily: 'Gilroy',
              fontSize: '14px',
              fontWeight: '500',
              lineHeight: '16px',
              letterSpacing: '0.035em',
              textAlign: 'left',
              color: 'rgb(85, 99, 132)',
              height: '44px',
              padding: '0 16px',
              width: '310px',
              border: 'none',
            },
            fieldset: {
              border: 'none',
            },
          }}
          placeholder="Логин"
        />
        <TextField
          id="password"
          type="password"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setErrors([])
            setPassword(event.target.value)
          }}
          sx={{
            width: '310px',
            borderRadius: '12px',
            background: '#1f232e',
            height: '44px',
            display: 'flex',
            justifyContent: 'center',
            input: {
              fontFamily: 'Gilroy',
              fontSize: '14px',
              fontWeight: '500',
              lineHeight: '16px',
              letterSpacing: '0.035em',
              textAlign: 'left',
              color: 'rgb(85, 99, 132)',
              height: '44px',
              padding: '0 16px',
              width: '310px',
            },
            fieldset: {
              border: 'none',
            },
          }}
          placeholder="Пароль"
        />
        <Button
          type="submit"
          sx={{color: 'rgb(85, 99, 132)', fontFamily: 'Gilroy'}}
        >
          Log in
        </Button>
      </form>
    </div>
  )
}
