import IconButton from '@mui/material/IconButton'
import ArrowBackIcon from '../../assets/arrow-left.svg'
import ArrowForwardIcon from '../../assets/arrow-right.svg'

import s from '../../userHoroscope.module.scss'

export default function TablePaginationActions(props) {
  const {count, page, rowsPerPage, onPageChange} = props

  const needToRenderCount = Math.ceil(count / rowsPerPage)
  const pageNumbers = Array.from({length: needToRenderCount}, (_, i) => i + 1)

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1)
  }
  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1)
  }
  const handlePageButtonClick = (event, inPage) => {
    onPageChange(event, inPage)
  }

  return (
    <div className={s.pagination}>
      <IconButton
        style={{marginRight: '16px'}}
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        <img src={ArrowBackIcon} alt="back" />
      </IconButton>

      {pageNumbers.map((el, i) => {
        return (
          <div
            key={i}
            className={`${s.pageNumber} ${page === i ? s.activeItem : ''}`}
            onClick={(e) => handlePageButtonClick(e, i)}
          >
            {el}
          </div>
        )
      })}

      <IconButton
        style={{marginLeft: '16px'}}
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        <img src={ArrowForwardIcon} alt="forward" />
      </IconButton>
    </div>
  )
}
