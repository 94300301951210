import arrowSVG from './assets/arrow.svg'
import useLocalStorage from '../../hooks/useLocalStorage'
import {useParams} from 'react-router-dom'
import {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import s from './editCompatibility.module.scss'
import {MenuItem, Select, ListItemIcon} from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'

export default function EditCompatibility() {
  const navigate = useNavigate()
  const [compatibilityResult, setCompatibilityResult] = useState<any>()
  const [text, setText] = useState('')
  const [love, setLove] = useState('')
  const [career, setCareer] = useState('')
  const [friendship, setFriendship] = useState('')
  const params = useParams()
  const [category, setCategory] = useState('day')
  const id = params.id
  const [token] = useLocalStorage('token')
  const apiUrl = process.env.REACT_APP_API_BACKEND_URL

  const goBack = () => {
    navigate('/compatibility')
  }

  const onChange = (e) => {
    switch (e.target.name) {
      case 'text':
        setText(e.target.value)
        break
      case 'love':
        setLove(e.target.value)
        break
      case 'career':
        setCareer(e.target.value)
        break
      case 'friendship':
        setFriendship(e.target.value)
        break
      case 'category':
        setCategory(e.target.value)
        break
      default:
        break
    }
  }

  const onSubmit = (e) => {
    e.preventDefault()

    const myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')
    myHeaders.append('Authorization', `Token ${token}`)

    const raw = JSON.stringify({
      id,
      text,
      love,
      career,
      friendship,
      category,
    })

    fetch(`/api/compatibility`, {
      method: 'PUT',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    }).catch(() => {})

    navigate('/compatibility')
  }

  const getCompatibility = () => {
    const myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')
    myHeaders.append('Authorization', `Token ${token}`)

    const raw = JSON.stringify({
      id: id,
    })
    fetch(`/api/compatibility/one`, {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    })
      .then((response) => response.json())
      .then((result) => {
        setCategory(result.category)
        setText(result.text)
        setLove(result.love)
        setCareer(result.career)
        setFriendship(result.friendship)
        setCategory(result.category)
        setCompatibilityResult(result)
      })
      .catch(() => {})
  }

  const deleteCompatibility = () => {
    const myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')
    myHeaders.append('Authorization', `Token ${token}`)

    const raw = JSON.stringify({
      id,
    })

    fetch(`/api/compatibility/delete`, {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    })

    navigate('/compatibility')
  }

  useEffect(() => {
    getCompatibility()
  }, [])

  return (
    <div className={s.editCompatibilityPage}>
      <div className={s.wrapper}>
        <div className={s.mainIcon}>
          <img
            src={arrowSVG}
            style={{width: '24px', height: '24px', cursor: 'pointer'}}
            onClick={goBack}
            alt="back"
          />
        </div>
        <p className={s.compatibilityId}>{id}</p>
        <div className={s.timeContainer}>
          <div className={s.createTime}>
            <p>ВРЕМЯ СОЗДАНИЯ</p>
            <p className={s.timeTextcreate}>12.12.12 в 16:32</p>
          </div>
          <div className={s.createTime}>
            <p>ВРЕМЯ ПОСЛЕДНЕГО ИЗМЕНЕНИЯ</p>
            <p className={s.timeTextcreate}>12.12.12 в 16:32</p>
          </div>
        </div>
        {compatibilityResult && (
          <form method="put" onSubmit={onSubmit}>
            <div className={s.containerInputs}>
              <div className={s.textField}>
                <p className={s.textHeader}>Общее</p>

                <textarea
                  name="text"
                  value={text}
                  onChange={onChange}
                  className={s.field}
                ></textarea>
              </div>

              <div className={s.textField}>
                <p className={s.textHeader}>Любовь</p>

                <textarea
                  name="love"
                  value={love}
                  onChange={onChange}
                  className={s.field}
                ></textarea>
              </div>

              <div className={s.textField}>
                <p className={s.textHeader}>Карьера</p>

                <textarea
                  name="career"
                  value={career}
                  onChange={onChange}
                  className={s.field}
                ></textarea>
              </div>

              <div className={s.textField}>
                <p className={s.textHeader}>Дружба</p>

                <textarea
                  name="friendship"
                  value={friendship}
                  onChange={onChange}
                  className={s.field}
                ></textarea>
              </div>
            </div>
            <div className={s.buttonsContainer}>
              <button type="submit" className={s.buttonSave}>
                Сохранить
              </button>

              <button
                type="button"
                onClick={deleteCompatibility}
                className={s.buttonDelete}
              >
                Удалить
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  )
}
